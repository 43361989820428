import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Spinner, } from 'reactstrap';

//redux
import { useSelector, useDispatch } from "react-redux";


// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { createSelector } from 'reselect';
import withRouter from "../../../Components/Common/withRouter";
import logoLight from "../../../assets/images/logo-light.png";
// actions
import { loginUser, socialLogin, resetLoginFlag } from "../../../slices/thunks";

import AuthSlider from '../../AuthenticationInner/authCarousel';
import ParticlesAuth from 'pages/AuthenticationInner/ParticlesAuth';
import { useAppDispatch, useAppSelector } from 'slices/hooks';
import { SignInUserArgs } from 'types';
import { resetProfileFlagChange } from 'slices/auth/profile/reducer';
import {auth, onAuthStatusChange} from 'configs/firebase.config';
import GoogleLogInComponent from 'Components/Common/GoogleLogIn';
import { toast, ToastContainer } from 'react-toastify';

const CoverSignIn = (props: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { user: Profile } = useAppSelector(state => state.Profile)
    const { token } = useAppSelector(state => state.Login)
    document.title = "Backoffice | Parta Rentals";
    const [userLogin, setUserLogin] = useState<SignInUserArgs>({
        email: "",
        password: ""
    });
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    
    const { loading, user } = useAppSelector(state => state.Login)
    // Inside your component
    
    useEffect(() => {
        if (Profile) {
            dispatch(resetProfileFlagChange())
        }
    }, [Profile, dispatch, navigate]);
    
    useEffect(() => {
        if (user && user) {
            const updatedUserData = user.email;
            const updatedUserPassword = user.password;
            setUserLogin({
                email: updatedUserData,
                password: updatedUserPassword
            });
        }
    }, [user]);
    
    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        
        initialValues: {
            email: userLogin.email || '',
            password: userLogin.password || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Por favor ingresa tu correo"),
            password: Yup.string().required("Por favor ingresa tu contraseña"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate)).catch((e)=> {
                console.log("cdle")
                
            });
        }
    });   
 
    
    const errornotify = (message: string) => toast(message, { position: "top-center", hideProgressBar: true, closeOnClick: false, className: 'bg-danger text-white' });
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-3 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="44" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Busca tu apartamento, consigue tu independencia</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className='justify-content-center'>
                            <Col lg={6}>
                                <Card className="overflow-hidden">
                                    <Row className="g-0">
                                        {/* <AuthSlider /> */}



                                        <Col lg={12}>
                                            <div className="p-lg-5 p-4">
                                                <div className='text-center'>
                                                    <h5 className="text-primary">¡Bienvenido a PartaRentals!</h5>
                                                    <p className="text-muted">Accede a tu cuenta</p>
                                                </div>

                                                <div className="mt-4">
                                                    <Form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                        action="#">

                                                        <div className="mb-3">
                                                            <Label htmlFor="username" className="form-label">Correo electrónico</Label>
                                                            <Input type="text" className="form-control" id="username"
                                                                name="email"
                                                                placeholder="Introduce tu correo electrónico"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.email || ""}
                                                                invalid={
                                                                    validation.touched.email && validation.errors.email ? true : false
                                                                }
                                                            />
                                                            {validation.touched.userName && validation.errors.userName ? (
                                                                <FormFeedback type="invalid">{validation.errors.userName}</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <div className="float-end">
                                                                <Link to="/auth-pass-reset-basic" className="text-success">¿Olvidaste tu contraseña?</Link>
                                                            </div>
                                                            <Label className="form-label" htmlFor="password-input">Contraseña</Label>
                                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                                <Input type={passwordShow ? "text" : "password"} className="form-control pe-5 password-input" id="password-input"
                                                                    name="password"
                                                                    placeholder="Introduce tu contraseña"
                                                                    value={validation.values.password || ""}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    invalid={
                                                                        validation.touched.password && validation.errors.password ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.password && validation.errors.password ? (
                                                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                                ) : null}
                                                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                            </div>
                                                        </div>

                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                            <Label className="form-check-label" htmlFor="auth-remember-check">Recuérdame</Label>
                                                        </div>

                                                        <div className="mt-4">
                                                            <Button color="success" className="w-100" type="submit">
                                                                {loading && <Spinner size="sm" className='me-2'> Loading... </Spinner>}
                                                                Iniciar sesión</Button>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            {/* <div className="signin-other-title">
                                                                <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                                            </div> */}

                                                            {/* <div>
                                                                <Button color="primary" className="btn-icon me-1"><i className="ri-facebook-fill fs-16"></i></Button>
                                                                <Button color="danger" className="btn-icon me-1"><i className="ri-google-fill fs-16"></i></Button>
                                                                <Button color="dark" className="btn-icon me-1"><i className="ri-github-fill fs-16"></i></Button>
                                                                <Button color="info" className="btn-icon"><i className="ri-twitter-fill fs-16"></i></Button>
                                                            </div> */}
                                                        </div>
                                                    </Form>
                                                    <div className="mt-4 text-center">
                                                        <div className="signin-other-title">
                                                            <h5 className="fs-13 mb-4 title">O inicia sesión con</h5>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                        <GoogleLogInComponent />                                                       
                                                    </div>
                                                    {/* <Button className="w-100" outline onClick={LoginWithGoogle}>
                                                        {loading && <Spinner size="sm" className='me-2'> Loading... </Spinner>}
                                                        <img src={GoogleIcon} alt='google icon' /> Iniciar sesión con Google
                                                    </Button> */}

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">¿No tienes una cuenta? <Link to="/register" className="fw-semibold text-default text-decoration-underline"> Regístrate aqui</Link> </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(CoverSignIn);