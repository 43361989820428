import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Widget from "./Widgets";
import BestSellingProducts from "./BestSellingProducts";
import RecentActivity from "./RecentActivity";
import RecentOrders from "./RecentOrders";
import Revenue from "./Revenue";
import SalesByLocations from "./SalesByLocations";
import Section from "./Section";
import StoreVisits from "./StoreVisits";
import TopSellers from "./TopSellers";
import DashboardTable from "./DashboardTable";
import { useProfileUtils } from "hooks/useProfileUtils";
import { useNavigate, useRouteError } from "react-router-dom";
import { useProfile } from "Components/Hooks/UserHooks";
import { useAppSelector } from "slices/hooks";

const DashboardEcommerce = () => {
  document.title = "Dashboard";
  const history = useNavigate();
  const {userProfile} = useProfile()
  const [rightColumn, setRightColumn] = useState<boolean>(true);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };
  const user = useAppSelector((state) => state.Profile.user)
  const { isProfileComplete } = useProfileUtils()

  useEffect(() => {
    if(user && !user?.roles.length) {
       history('/pages-complete-register')
    } else if(!isProfileComplete) {
       history('/pages-profile-settings')
    }
  }, [history, isProfileComplete, user?.roles.length]);

  return (
    <React.Fragment>

    </React.Fragment>
  );
};

export default DashboardEcommerce;
