import { cl } from "@fullcalendar/core/internal-common";
import { current } from "@reduxjs/toolkit";
import { useHandleApiCall } from "hooks/useHandleApiCall";
import { useProfileUtils } from "hooks/useProfileUtils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import { GetUserPaymentMethods } from "service/paymentMethods";
import { useAppSelector, useAppDispatch } from "slices/hooks";
import { Role } from "types";
import { label } from "yet-another-react-lightbox/*";

import * as UserService from 'service/users';
import { useSelector } from "react-redux";
import { createSelector } from 'reselect';
import { profileSuccess } from 'slices/auth/profile/reducer';
import { changeSidebarVisibility } from '../slices/thunks';
import { CustomButton } from "Components/UI/CustomButton";

enum MenuItems {
    Dashboard = 'Dashboard',
    FavoriteProperties = 'Propiedades favoritas',
    Users = 'Usuarios',
    Properties = 'Properties',
    Contracts = 'Contratos',
    Payments = 'Pagos',
    Agents = 'Agents',
}

const RoleEquivalent = {
    [Role.Tenant]: "Arrendatario",
    [Role.Owner]: "Arrendador",
    [Role.Agent]: "Arrendador",
    [Role.Admin]: "Admin",

}




const Navdata = () => {
    const history = useNavigate();
    const { user } = useAppSelector(state => state.Profile)
    const { isProfileComplete } = useProfileUtils()
    const { pathname } = useLocation()
    //Parta Rentals
    const [isUsers, setIsUsers] = useState<boolean>(false);

    const [isProperties, setIsProperties] = useState<boolean>(false);
    const [isContract, setIsContract] = useState<boolean>(false);
    const [isPayments, setIsPayments] = useState<boolean>(false);
    const [isFavoriteProperties, setIsFavoriteProperties] = useState<boolean>(false);

    const [currentRole, setCurrentMode] = useState<Role>(user?.roles[0] || Role.Tenant);
    const dispatch = useAppDispatch();

    const handleUpdateUserApiCall = useHandleApiCall(UserService.Update, {
        onSuccess: (data) => {
            history("/")
        }
    })

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems: any = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement;
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }



    const getPaymentMethods = useHandleApiCall(GetUserPaymentMethods, {
        recallArguments: [{
            userId: user?._id ?? ""
        }],
        ignoreError: true
    })

    const checkProtectedRoutes = useCallback(async (url: string) => {
        if (!user?._id) return
        if (user._id === "") return
        console.log("checkProtectedRoutes")
        if (user.roles[0] === Role.Tenant) {
            if (!isProfileComplete) {
                const completeProfileURL = ["/contracts-offers-preview", "/finished-contracts-preview", "/active-contracts-preview"]
                if (completeProfileURL.includes(url)) {
                    history("/pages-profile-settings")
                    setTimeout(() => toast.error("Completa tu perfil para poder abrir esta seccion"))
                    return
                }
            }
            const paymentMethodURL = ["/invoice-claim"]
            if (paymentMethodURL.includes(url)) {
                const paymentMethods = await getPaymentMethods.refetch({
                    userId: user?._id ?? ""
                })
                if (paymentMethods?.filter(el => el.isActive)?.length === 0) {
                    history("/payment-methods")
                    setTimeout(() => toast.error("Registra un método de pago para poder abrir esta seccion"))
                    return
                }
            }

        }
        if (user.roles[0] === Role.Owner) {
            if (!isProfileComplete && url !== "/pages-profile-settings") {
                history("/pages-profile-settings")
                setTimeout(() => toast.error("Completa tu perfil para poder abrir esta seccion"))
                return
            }
            const paymentMethodURL = ["/payment-history", "/account-state", "/invoice-claim", "/contracts-register"]


            if (paymentMethodURL.includes(url) && url !== "/payment-methods") {
                if (paymentMethodURL.includes(url)) {
                    const paymentMethods = await getPaymentMethods.refetch({
                        userId: user?._id
                    })

                    if (paymentMethods?.filter(el => el.isActive)?.length === 0) {
                        history("/payment-methods")
                        setTimeout(() => toast.error("Registra un método de pago para poder abrir esta seccion"))
                        return
                    }
                }
            }
        }
        if (user.roles[0] === Role.Agent) {
            if (!isProfileComplete && url !== "/pages-profile-settings") {
                history("/pages-profile-settings")
                setTimeout(() => toast.error("Completa tu perfil para poder abrir esta seccion"))
                return
            }
        }
        return history(url)
    }, [history, isProfileComplete, user?._id, user?.roles, pathname])

    useEffect(() => {
        checkProtectedRoutes(pathname)
    }, [checkProtectedRoutes, pathname, user?._id])

    //TODO: Avance hasta aquí.
    const getStyledLabel = (text = "", color = 'black') => (
        <div style={{ border: '2px solid #1BCEA3', padding: '10px', borderRadius: '5px', backgroundColor: '#1BCEA3', color }}>
            {text};
        </div>
    );

    const updateUserHandleSubmit = useCallback(async (e: UserService.IUpdateUser) => {
        if (!e._id) return
        let data = await handleUpdateUserApiCall.refetch({
            id: e._id,
        }, e)
        dispatch(profileSuccess({ status: "success", data }))
        return data
    }, [dispatch])

    const handleRoleChange = (newMode: Role) => {
        setCurrentMode(newMode);

        if (user?._id) {
            updateUserHandleSubmit({
                _id: user._id,
                roles: [newMode],
                email: user.email,
                name: user.name,
                emailVerified: user.emailVerified,
                password: user.password,
                phone: user.phone,
                surname: user.surname,
                verifyToken: user.verifyToken,
                address: user.address,
            });
        }
    };

    const menuItems: any = useMemo(() => {
        return [
            user?.roles && [Role.Tenant].includes(user?.roles[0]) && {
                id: "favoriteproperties",
                label: "Propiedades favoritas",
                icon: "ri-dashboard-2-line",
                link: "/#",
                stateVariables: isFavoriteProperties,
                click: function (e: any) {
                    e.preventDefault();
                    setIsFavoriteProperties(prev => !prev);
                    updateIconSidebar(e);
                },
            },
            user?.roles && [Role.Admin].includes(user?.roles[0]) && {
                id: "users",
                label: "Usuarios",
                icon: " ri-hotel-line",
                link: "/property",
                stateVariables: isUsers,
                click: function (e: any) {
                    e.preventDefault();
                    setIsUsers(prev => !prev);
                    updateIconSidebar(e);
                },
                subItems: [
                    {
                        id: "usersdetails",
                        label: "Visualizar un usuario",
                        link: "/pages-user-register",
                        parentId: "users",
                    },
                    {
                        id: "usersform",
                        label: "Crear un usuario",
                        link: "/pages-users-form",
                        parentId: "users",
                    },
                ]
            },
            user?.roles && [Role.Owner, Role.Agent, Role.Admin].includes(user?.roles[0]) && {
                id: "properties",
                label: "Propiedades",
                icon: " ri-hotel-line",
                link: "/property",
                stateVariables: isProperties,
                click: function (e: any) {
                    e.preventDefault();
                    setIsProperties(prev => !prev);
                    updateIconSidebar(e);
                },
                subItems: [
                    {
                        id: "propertiesform",
                        label: "Subir una propiedad",
                        link: "/pages-property-register",
                        parentId: "properties",
                    },
                    {
                        id: "propertiesdetails",
                        label: "Visualizar propiedades",
                        link: "/pages-property-lists",
                        parentId: "properties",
                    },
                ].filter(el => el)
            },
            user?.roles && [Role.Owner, Role.Agent, Role.Admin, Role.Tenant].includes(user?.roles[0]) && {
                id: "contract",
                label: "Contratos",
                icon: "ri-file-paper-2-line",
                link: "/contracts",
                stateVariables: isContract,
                click: function (e: any) {
                    e.preventDefault();
                    setIsContract(prev => !prev);
                    updateIconSidebar(e);
                },
                subItems: [
                    [Role.Owner, Role.Agent, Role.Admin].includes(user.roles[0]) && {
                        id: "contractform",
                        label: "Registrar contrato",
                        link: "/contracts-register",
                        parentId: "contract",
                    },
                    {
                        id: "contractactive",
                        label: "Contratos activos",
                        link: "/active-contracts-preview",
                        parentId: "contract",
                    },
                    {
                        id: "contract-inactive",
                        label: "Contratos inactivos",
                        link: "/finished-contracts-preview",
                        parentId: "contract",
                    },
                    {
                        id: "contractoffers",
                        label: "Ofertas de contratos",
                        link: "/contracts-offers-preview",
                        parentId: "contract",
                    },
                ].filter(el => el)
            },
            user?.roles && [Role.Owner, Role.Agent, Role.Admin, Role.Tenant].includes(user?.roles[0]) && {
                id: "payments",
                label: "Pagos",
                icon: " ri-secure-payment-line",
                link: "/payments",
                stateVariables: isPayments,
                click: function (e: any) {
                    e.preventDefault();
                    setIsPayments(prev => !prev);
                    updateIconSidebar(e);
                },

                subItems: [
                    {
                        id: "paymentshistory",
                        label: "Historial de pagos",
                        link: "/payment-history",
                        parentId: "payments",
                    },
                    user?.roles && [Role.Owner, Role.Tenant, Role.Admin].includes(user?.roles[0]) ? {
                        id: "paymentsmethods",
                        label: "Métodos de pago",
                        link: "/payment-methods",
                        parentId: "payments",
                    } : null,
                    {
                        id: "accountState",
                        label: "Estado de cuenta",
                        link: "/account-state",
                        parentId: "payments",
                    },
                    {
                        id: "invoiceClaim",
                        label: "Reclamo de factura",
                        link: "/invoice-claim",
                        parentId: "payments",
                    },
                ].filter(el => el)
            },
            /* {
                id: "agents",
                label: "Agentes",
                icon: "ri-customer-service-2-fill",
                link: "/pages-agents",
                click: function (e: any) {
                    e.preventDefault();
                    updateIconSidebar(e);
                },
            }, */
        ].filter(el => el);
    }, [isContract, isFavoriteProperties, isPayments, isProperties, isUsers, user?.roles])

    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;