import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Label, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledDropdown } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';

import { toast } from 'react-toastify';
import { GetContracts, UpdateContract } from 'service/contracts';
import { useAppSelector } from 'slices/hooks';
import { Role } from 'types';
import { ContractStatus, IContract } from 'types/api/contracts';
import AvatarIcon from '../../../../assets/images/users/avatar-3.jpg';
import { IPayment, PaymentStatus, RejectionState, RejectionStateMap } from 'types/api/payments';
import { CustomButton } from 'Components/UI/CustomButton';
import { useHandleApiCall } from 'hooks/useHandleApiCall';
import { UpdatePaymentStatus } from 'service/payments';
import Select from 'react-select';
import { amountFormatter } from 'helpers/number_helper';



type contractType = "contracts" | "offers"

interface TemplatePreviewContractsProps {
    statusFilter?: ContractStatus[];
    title?: string;
    headerTitle?: string;
    contractType?: contractType;
    itemsActions: {
        label: string,
        render?: (v: IContract) => React.ReactElement,
        action: (v: IContract, reload: () => {}) => void, key?: string,
        validation?: (v: IContract) => boolean,
        icon?: string,
    }[],
}

const badgeText = {
    [ContractStatus.ACTIVE]: "Activo",
    [ContractStatus.PENDING_APPROVAL]: "APROBACIÓN PENDIENTE",
    [ContractStatus.PENDING_COMPLETION]: "FINALIZACIÓN PENDIENTE",
    [ContractStatus.COMPLETED]: "FINALIZADO",
    [ContractStatus.PENDING_PAYMENT_APPROVAL]: "APROBACIÓN DE PAGO PENDIENTE",
    [ContractStatus.REJECTED]: "RECHAZADO",
    [ContractStatus.REJECTED_PAYMENT]: "PAGO RECHAZADO",
    [ContractStatus.NON_RENEWABLE]: "No renovable",

}

const badgeColor = {
    [ContractStatus.ACTIVE]: "success",
    [ContractStatus.PENDING_APPROVAL]: "warning",
    [ContractStatus.PENDING_COMPLETION]: "warning",
    [ContractStatus.COMPLETED]: "success",
    [ContractStatus.PENDING_PAYMENT_APPROVAL]: "warning",
    [ContractStatus.REJECTED]: "danger",
    [ContractStatus.REJECTED_PAYMENT]: "danger",
    [ContractStatus.NON_RENEWABLE]: "warning",
}

const ContractBadge = ({ status }: { status: ContractStatus }) => {
    return <h5><Badge color={badgeColor[status]} className="badge-soft-primary font-size-12">{badgeText[status]}</Badge></h5>
}

const TemplatePreviewContracts = (props: TemplatePreviewContractsProps) => {
    const user = useAppSelector(state => state.Profile.user)
    document.title = props.title ?? "Visualizar contratos | Partarentals";
    const [selectedContract, setSelectedContract] = useState<IContract | null>(null);
    const navigate = useNavigate()
    /* const [modal_declined, setmodal_declined] = useState<boolean>(false); */
    /* const [offset, setOffset] = useState(0); */
    /* const [skip, setSkip] = useState(0) */
    /* const [pageIndex, setPageIndex] = useState<number>(0) */
    /* const [modal_center, setmodal_center] = useState<boolean>(false); */
    const handleUpdateContractApiCall = useHandleApiCall(UpdateContract)
    const handleGetContractsApiCall = useHandleApiCall(GetContracts)
    const handleUpdatePaymentApiCall = useHandleApiCall(UpdatePaymentStatus)
    const [limit] = useState(10)
    const [contracts, setContracts] = useState<IContract[]>([])
    const [pageSize, setPageSize] = useState<number>(0)
    const [canNextPage, setCanNextPage] = useState<boolean>(true)
    const [canPreviousPage, setCanPreviousPage] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [showAcceptPaymentModal, setShowAcceptPaymentModal] = useState<boolean>(false);
    const [rejectedPaymentForm, setRejectedPaymentForm] = useState<{
        rejectionReason: string,
        rejectionState: RejectionState
    }>({ rejectionReason: "", rejectionState: RejectionState.INCOMPLETE_PAYMENT })


    const tog_accept_payment = useCallback(() => {
        setShowAcceptPaymentModal(prev => !prev);
    }, [])
    const [showDeclinePaymentModal, setShowDeclinePaymentModal] = useState<boolean>(false);
    const tog_decline_payment = useCallback(() => {
        setShowDeclinePaymentModal(prev => !prev);
    }, [])

    const pageOptions = useMemo(() => {
        return pageSize > 0 ? new Array(Math.ceil((pageSize / limit))).fill(null).map((el, i) => i) : []
    }, [limit, pageSize])


    const nextPage = () => {
        if (page < pageOptions.length - 1) {
            setPage(page + 1)
        }
    }
    const previousPage = () => {
        if (page > 0) {
            setPage(page - 1)
        }
    }

    const gotoPage = (page: number) => {
        setPage(page)
    }

    const handleGetContracts = useCallback(async () => {
        try {
            const offset = page * limit
            const skip = offset + limit
            const res = await handleGetContractsApiCall.refetch({ limit, offset: offset, skip: skip, status: props.statusFilter })
            if (!res) return
            setContracts(res.data)
            setPageSize(res.count)
        } catch (error) {

        }
    }, [limit, page, props.statusFilter])

    useEffect(() => {
        handleGetContracts()
        if (page === 0) {
            setCanPreviousPage(false)
            setCanNextPage(true)
        } else if (page === pageOptions.length - 1) {
            setCanNextPage(false)
            setCanPreviousPage(true)
        } else {
            setCanNextPage(true)
            setCanPreviousPage(true)
        }
    }, [page, pageOptions.length, user?.roles])


    const handlePaymentApprove = useCallback(async () => {
        try {
            if (!selectedContract) return

            await handleUpdatePaymentApiCall.refetch(selectedContract.lastPayment._id, {
                status: PaymentStatus.COMPLETED,
            })
            handleGetContracts()
            setShowAcceptPaymentModal(false)
            navigate("/active-contracts-preview")
            toast.success("Pago aceptado...")
        } catch (error) {
            toast.error("Ha ocurrido un error al activar el contrato")
        }
    }, [navigate, selectedContract])

    const handlePaymentDenied = useCallback(async () => {
        try {
            if (!selectedContract) return

            await handleUpdatePaymentApiCall.refetch(selectedContract.lastPayment._id, {
                status: PaymentStatus.REJECTED,
            })
            handleGetContracts()
            setShowDeclinePaymentModal(false)
            toast.success("Pago rechazado.")
        } catch (error) {
            toast.error("Ha ocurrido un error al rechazar el contrato")
        }
    }, [navigate, selectedContract])

    const columns = useMemo(
        () => [
            {
                Header: "Propiedad",
                accessor: (contract: IContract) => <div>
                    <img src={contract.property?.images[0]?.url ?? AvatarIcon} alt="" className="rounded-circle avatar-xs material-shadow" style={{ marginRight: 10 }} />
                    <Link to={`/pages-property-details/${contract.property?._id}`} className='enlace-tabla'>Ver propiedad</Link>
                </div>,
                disableFilters: true,
                filterable: false,
            },
            props.contractType === "offers" ? {
                Header: "Fecha de envío",
                accessor: (contract: IContract) => <span>{new Date(contract.createdAt).toLocaleDateString()}</span>,
                disableFilters: true,
                filterable: false,
            } : null,
            props.contractType === "contracts" ? {
                Header: "Inicio y culminación",
                accessor: (contract: IContract) => <span>{new Date(contract.startDate).toLocaleDateString()} - {new Date(contract.endDate).toLocaleDateString()}</span>,
                disableFilters: true,
                filterable: false,
            } : null,
            {
                Header: "Precio",
                accessor: (contract: IContract) => <span>{amountFormatter(contract.agreedPrice, contract.currency)}</span>,
                disableFilters: true,
                filterable: false,
            },
            //@ts-ignore
            [Role.Admin, Role.Owner].includes(user?.roles[0] ?? '') ? {
                Header: "Inquilino",
                accessor: (contract: IContract) => <span>{contract.tenant?.name} {contract.tenant?._id === user?._id && "*"}</span>,
                disableFilters: true,
                filterable: false,
            } : null,
            //@ts-ignore
            [Role.Admin, Role.Tenant].includes(user?.roles[0] ?? '') ? {
                Header: "Arrendador",
                accessor: (contract: IContract) => <span>{contract.owner?.name} {contract.owner?._id === user?._id && "*"}</span>,
                disableFilters: true,
                filterable: false,
            } : null,
            {
                Header: "Estado",
                accessor: (contract: IContract) => <ContractBadge status={contract.status} />,
                disableFilters: true,
                filterable: false,
            },
            props.contractType === "offers" ? {
                Header: "Inicio y culminación",
                accessor: (contract: IContract) => <span>{new Date(contract.startDate).toLocaleDateString()} - {new Date(contract.endDate).toLocaleDateString()}</span>,
                disableFilters: true,
                filterable: false,
            } : null,
            props.contractType === "contracts" ? {
                Header: "Reajuste anual",
                accessor: (contract: IContract) => <span>{contract.annualIncrement}%</span>,
                disableFilters: true,
                filterable: false,
            } : null,
            props.contractType === "contracts" ? {
                Header: "Recarga moratorio",
                accessor: (contract: IContract) => <span>{amountFormatter(contract.lateInterest,contract.currency)}</span>,
                disableFilters: true,
                filterable: false,
            } : null,
            {
                Header: "Depósito garantía",
                accessor: (contract: IContract) => <span>{amountFormatter(contract.deposit,contract.currency)}</span>,
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Penalidad",
                accessor: (contract: IContract) => <span>{amountFormatter(contract.terminationPenalty,contract.currency)}</span>,
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Acciones",
                accessor: (contract: IContract) => {
                    return contract?.status === ContractStatus.PENDING_PAYMENT_APPROVAL && (contract.owner._id === user?._id || contract.agent._id === user?._id)
                        ?

                        <div className="d-flex justify-content-start" >
                            <UncontrolledDropdown className="card-header-dropdown" direction='start'>
                                <DropdownToggle tag="a" className="btn btn-link text-secondary p-2 py-1 text-decoration-none fs-15 bg-light" role="button">
                                    <span className="fs-18"><i className="mdi mdi-dots-horizontal btn-secondary"></i></span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem
                                        onClick={() => {
                                            navigate(`/contracts-details/${contract._id}`);
                                        }}>
                                        <span className="icon-off"><i className="ri-eye-line align-bottom me-1"></i> Ver detalle</span>
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            tog_accept_payment()
                                            setSelectedContract(contract)
                                        }}>
                                        <span className="icon-off text-success"><i className="ri-check-fill align-bottom me-1"></i> Aceptar pago del contrato</span>
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            tog_decline_payment()
                                            setSelectedContract(contract)
                                        }}>
                                        <span className="icon-off text-danger"><i className="ri-close-fill align-bottom me-1"></i> Rechazar pago del contrato</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div >
                        :
                        <div className="d-flex justify-content-start">
                            <UncontrolledDropdown className="card-header-dropdown" direction='start'>
                                <DropdownToggle tag="a" className="btn btn-link text-secondary p-2 py-1 text-decoration-none fs-15 bg-light" role="button">
                                    <span className="fs-18"><i className="mdi mdi-dots-horizontal btn-secondary"></i></span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    {
                                        props.itemsActions.map((el, i) => {
                                            if (el.validation)
                                                return el.validation(contract) ?
                                                    <DropdownItem key={i}
                                                        onClick={() => el.action(contract, handleGetContracts)}>
                                                        {el.render ? el.render(contract) : <span className="icon-off">{el.icon && <i className={el.icon && `${el.icon} align-bottom me-1`}></i>} {el.label}</span>}
                                                    </DropdownItem> : null
                                            else return <DropdownItem key={i} onClick={() => {
                                                el.action(contract, handleGetContracts)
                                            }}>{el.render ? el.render(contract) : <span className="icon-off">{el.icon && <i className={el.icon && `${el.icon} align-bottom me-1`}></i>} {el.label}</span>}
                                            </DropdownItem>
                                        }
                                        )
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                },
                disableFilters: true,
                filterable: false,
            },
        ].filter(el => el),
        [handleGetContracts, props.contractType, props.itemsActions, tog_accept_payment, tog_decline_payment, user?._id, user?.roles]
    );
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Visualizar contratos" pageTitle="Pages" />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">{props.title}</h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="table-responsive">
                                                {
                                                    !handleGetContractsApiCall.data?.data?.length
                                                        ? <div className="no-information bg-light">
                                                            <p>No hay datos disponibles para visualizar</p>
                                                        </div>
                                                        : <Table className="align-middle table-nowrap mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    {
                                                                        columns.map((column, index) => (
                                                                            <th key={index} scope="col">{column?.Header}</th>
                                                                        ))
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    contracts.map((contract, index) => (
                                                                        <tr key={contract._id}>
                                                                            {
                                                                                columns.map((column, index) => (
                                                                                    <td key={index}>{column?.accessor(contract)}</td>
                                                                                ))
                                                                            }
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </Table>
                                                }


                                            </div>
                                            <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                                                <div className="col-sm">
                                                    <div className="text-muted">Mostrando<span className="fw-semibold ms-1">{contracts.length}</span> de <span className="fw-semibold">{pageSize}</span> Resultados
                                                    </div>
                                                </div>
                                                <div className="col-sm-auto">
                                                    <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                                                        <li className={!canPreviousPage ? "page-item disabled" : "page-item"}>
                                                            <Link to="#" className="page-link" onClick={previousPage}>Anterior</Link>
                                                        </li>
                                                        {pageOptions.map((item: any, key: any) => (
                                                            <React.Fragment key={key}>
                                                                <li className="page-item">
                                                                    <Link to="#" className={page === item ? "page-link active" : "page-link"} onClick={() => gotoPage(item)}>{item + 1}</Link>
                                                                </li>
                                                            </React.Fragment>
                                                        ))}
                                                        <li className={!canNextPage ? "page-item disabled" : "page-item"}>
                                                            <Link to="#" className="page-link" onClick={nextPage}>Siguiente</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>


                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>


            <Modal
                isOpen={showAcceptPaymentModal}
                toggle={() => {
                    tog_accept_payment();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="bx bx-check-shield text-success" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Estas seguro de aprobar este pago?</h4>
                        <p className="text-muted mb-4">Una vez aprobado no se podrá modificar</p>
                        <div className="hstack gap-2 justify-content-center">
                            <CustomButton color="light" onClick={tog_accept_payment}>Cancelar</CustomButton>
                            <CustomButton loading={handleUpdateContractApiCall.loading} color="success" onClick={handlePaymentApprove}>Aprobar</CustomButton>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal
                isOpen={showDeclinePaymentModal}
                toggle={tog_decline_payment}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="ri-spam-fill text-danger" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Estas seguro de rechazar este pago?</h4>
                        <form action="">
                            <div className="mb-3 text-start">
                                <Label htmlFor="currency" className="form-label">Motivo del rechazo</Label>
                                <Select
                                    placeholder="Seleccione un motivo"
                                    className="mb-0"
                                    value={{ value: rejectedPaymentForm.rejectionReason, label: RejectionStateMap[rejectedPaymentForm.rejectionState] }}
                                    onChange={(selectCountry: any) => {
                                        setRejectedPaymentForm({ ...rejectedPaymentForm, rejectionState: selectCountry.value })
                                    }}
                                    options={[
                                        { value: RejectionState.INCOMPLETE_PAYMENT, label: RejectionStateMap[RejectionState.INCOMPLETE_PAYMENT] },
                                        { value: RejectionState.NO_PAYMENT_RECEIVED, label: RejectionStateMap[RejectionState.NO_PAYMENT_RECEIVED] },
                                        { value: RejectionState.OTHER, label: RejectionStateMap[RejectionState.OTHER] },
                                    ]}
                                    id="country-select"
                                ></Select>

                            </div>
                            <div className="mb-3 text-start">
                                <Label htmlFor="exampleFormControlTextarea5" className="form-label">Example Textarea</Label>
                                <textarea className="form-control" id="exampleFormControlTextarea5" rows={3} onChange={
                                    (e) => setRejectedPaymentForm({ ...rejectedPaymentForm, rejectionReason: e.target.value })
                                }></textarea>
                            </div>
                            <div className="hstack gap-2 justify-content-center">
                                <CustomButton color="light" onClick={() => setShowDeclinePaymentModal(false)}>Cancelar</CustomButton>
                                <CustomButton color="danger" loading={handleUpdatePaymentApiCall.loading} onClick={() => selectedContract?.lastPayment && handlePaymentDenied()}>Rechazar</CustomButton>
                            </div>
                        </form>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default TemplatePreviewContracts;