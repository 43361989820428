import { GoogleLogin } from '@react-oauth/google';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'slices/hooks';
import { loginUserWithGoogle } from 'slices/thunks';
import styles from 'yet-another-react-lightbox/styles.css';

interface GoogleLogInComponentProps {
  texts?: "signin" | "continue_with" | "signup_with" | "signin_with"
}

const GoogleLogInComponent = (props: GoogleLogInComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const handleGoogleSuccess = async (credentialResponse: any) => {
    if (credentialResponse.credential) {     
        dispatch(loginUserWithGoogle(credentialResponse.credential, navigate));  
    }
  };

  return (
    <GoogleLogin
      useOneTap
      logo_alignment='center'
      onSuccess={handleGoogleSuccess}
      // onSuccess={async (credentialResponse: any) => {
      //   if (credentialResponse.credential) {
      //     dispatch(loginUserWithGoogle(credentialResponse.credential, navigate));
      //   }
      // }}
      onError={() => {
        console.log('Login Failed');
      }}
      width={300}
      text={props.texts}
    />
  );
}

export default GoogleLogInComponent
/*  */