import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, FormGroup, Input, Label, List, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Row, TabContent, Table, TabPane, UncontrolledTooltip } from 'reactstrap';
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import logoSm from "../../../../assets/images/logo-sm-2.png"
import top_cover_bar from "../../../../assets/images/top_cover_bar.jpg"


//import images
import progileBg from '../../../../assets/images/profile-bg.jpg';
import avatar1 from '../../../../assets/images/users/profile-logo.jpeg';
import { Agent } from 'http';
import { useAppDispatch, useAppSelector } from 'slices/hooks';
import { ChangePasswordArgs, IRating, IUser, RatingFilter, Role } from 'types';
import { useFormik } from "formik";
import * as UserService from 'service/users';
import * as AuthService from 'service/auth';
import * as RatingService from 'service/rating';
import { profileError, profileSuccess } from 'slices/auth/profile/reducer';
import { Rating } from 'react-simple-star-rating';
import { fileToBinaryString } from 'helpers/file';
import Dropzone, { Accept } from 'react-dropzone';
import { toast } from 'react-toastify';
import { DNITypesOptions } from 'Components/constants/data';
import { useDebounceCallback } from 'usehooks-ts';
import { ICreateContract } from 'types/api/contracts';
import * as Yup from "yup";
import { allRegionMap } from 'helpers/region';
import { phoneUtilities } from 'helpers/phoneUtils';
import { useHandleApiCall } from 'hooks/useHandleApiCall';
import { CustomButton } from 'Components/UI/CustomButton';
import { ScaleLoader } from 'react-spinners';
import { use } from 'i18next';
interface UpdateUserFormValidation extends UserService.IUpdateUser {
}
interface ChangePasswordFormValidation extends ChangePasswordArgs {
    confirmNewPassword: string
}

interface ReviewTRProps extends IRating {
    user: IUser | null;
}

/* const convertImageURLToImageFile = async (url: string) => {
    try {
        return fetch(url)
            .then(res => res.blob())
            .then(blob => new File([blob], 'image.jpg', { type: 'image/jpeg' }))

    } catch (error) {
        console.log(error)
    }
} */

const partaRentalGreen = '#00c689'
const activeClass = {
    color: partaRentalGreen
}

const dniSplit = (dni: string) => {
    const dniSplit = `${dni}`?.split('-')

    if (dniSplit?.length === 2) {
        return {
            dniType: dniSplit[0],
            dniNumber: dniSplit[1]
        }
    } else if (dniSplit?.length === 1) {
        return {
            dniType: DNITypesOptions[0].value,
            dniNumber: dniSplit[0]
        }
    } else {
        return {
            dniType: DNITypesOptions[0].value,
            dniNumber: ''
        }
    }
}


const ReviewTR = ({ owner, tenant, createdAt, rating, comment, user }: ReviewTRProps) => {
    const targetUser = useMemo(() => {
        if (user?.roles[0] === Role.Tenant) {
            return owner
        } else if (user?.roles[0] === Role.Owner) {
            return tenant
        }
    }, [user, owner, tenant])
    return (
        <tr>
            <td>
                <Link to="#" className="fw-medium">
                    {`${targetUser?.name} ${targetUser?.surname}`}
                </Link>
            </td>
            <td>{new Date(createdAt).toDateString()}</td>
            <td style={{ padding: '0', margin: '0', whiteSpace: 'nowrap', minWidth: '200px' }}>
                <Row noGutters className="align-items-center" style={{ padding: '0', margin: '0' }}>
                    <Col xs="auto" style={{ padding: '0', margin: '0' }}>
                        <Rating
                            initialValue={rating}
                            size={22}
                            allowHover={false}
                            allowFraction={true}
                            readonly={true}
                        />
                    </Col>
                    <Col xs="auto" style={{ padding: '0', margin: '0' }}>
                        <span style={{ fontSize: '12px', margin: '0 0 0 5px' }}>
                            {rating} estrellas
                        </span>
                    </Col>
                </Row>
            </td>
            <td style={{ width: '100%' }}>
                <p style={{ margin: '0' }}>{comment}</p>
            </td>
        </tr>)
}


const Settings = () => {
    /*  const [dropdownOpen, setDropdownOpen] = useState(false);
 
     const toggle = () => setDropdownOpen((prevState) => !prevState); */

    const { user } = useAppSelector(state => state.Profile)
    const dispatch = useAppDispatch()
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState("1");
    const [photoDNIImageFront, setPhotoDNIImageFront] = useState<File | null>(null)
    const [photoDNIImageBack, setPhotoDNIImageBack] = useState<File | null>(null)
    const [photoCompanyLogo, setPhotoCompanyLogo] = useState<File | null>(null)
    const [photoLegalStatus, setPhotoLegalStatus] = useState<File | null>(null)
    const [userImage, setUserImage] = useState<File | null>(null)
    const [keyPhotoDNIImageFront, setKeyPhotoDNIImageFront] = useState<number>(Date.now())
    const [keyPhotoDNIImageBack, setKeyPhotoDNIImageBack] = useState<number>(Date.now())
    const [keyPhotoCompanyLogo, setKeyPhotoCompanyLogo] = useState<number>(Date.now())

    const [checkboxRatingTarget, setCheckboxRatingTarget] = useState<"made" | "receive" | null>("made")
    const [dniType, setDniType] = useState(DNITypesOptions[0].value)
    const [dniNumber, setDniNumber] = useState("")
    const [phoneDial, setPhoneDial] = useState(allRegionMap[0].MobileCode)
    const [phoneNumber, setPhoneNumber] = useState("")
    const [showJuridicDocInput, setShowJuridicDocInput] = useState<boolean>(false)
    const handleGetRatingApiCall = useHandleApiCall(RatingService.GetRatings)
    const handleUpdateUserApiCall = useHandleApiCall(UserService.Update)

    const fullPhone = useMemo(() => {
        return `${phoneDial}${phoneNumber}`
    }, [phoneDial, phoneNumber])
    const tabChange = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    document.title = "Profile Settings | Velzon - React Admin & Dashboard Template";

    const handleDniTypeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDniType = event.target.value;
        setDniType(selectedDniType);
        setShowJuridicDocInput(selectedDniType === 'CPJ');
    }, [])
    useEffect(() => {
        if (user && user.dni) {
            const type = user.dni.split('-')[0]
            if (type === "CPJ") {
                setShowJuridicDocInput(true)
            }
        }
    }, [user])


    const updateUserHandleSubmit = useCallback(async (e: UserService.IUpdateUser) => {
        if (!e._id) return;
        if (userImage) {
            e.image = userImage
        }
        if (photoDNIImageFront) {
            e.photoDni = photoDNIImageFront
        }

        if (photoDNIImageBack) {
            e.photoDniBehind = photoDNIImageBack
        }

        if (photoCompanyLogo) {
            e.companyLogo = photoCompanyLogo
        }

        if (photoLegalStatus) {
            e.legalStatus = photoLegalStatus
        }

        let data = await handleUpdateUserApiCall.refetch({
            id: e._id,
        }, e)
        return data
    }, [userImage, photoDNIImageFront, photoDNIImageBack, photoCompanyLogo, photoLegalStatus])

    const changePasswordHandleSubmit = useCallback(async (e: ChangePasswordFormValidation) => {
        try {
            let data = await AuthService.ChangePassword({
                currentPassword: e.currentPassword,
                newPassword: e.newPassword
            })
            toast.success('Tu contraseña ha sido actualizada exitosamente.');
            return data
        } catch (error) {
            toast.error('Error al actualizar la contraseña. Por favor, intenta nuevamente.');
            /* toast.error('Error al actualizar la contraseña. La contraseña actual no es correcta.'); */
        }
    }, [])


    const updateUserValidation = useFormik<UpdateUserFormValidation>({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            _id: user?._id ?? '',
            name: user?.name ?? '',
            surname: user?.surname ?? '',
            email: user?.email ?? '',
            phone: user?.phone ?? '',
            roles: user?.roles ?? [Role.Owner],
            image: undefined,
            verifyToken: user?.verifyToken ?? '',
            emailVerified: user?.emailVerified ?? false,
            dni: dniSplit(user?.dni ?? '').dniNumber,
            address: user?.address,
            password: user?.password ?? '',
            company: user?.company,
        },

        validationSchema: Yup.object<UpdateUserFormValidation>({
            name: Yup.string().required('El nombre es requerido'),
            surname: Yup.string().required('El apellido es requerido'),
            phone: Yup.string().required('El telefono es requerido'),
            dni: Yup.string().required('El dni es requerido'),
            address: Yup.string().required('El direccion es requerido'),
        }),

        onSubmit: async (values) => handleUpdateUserSubmit(values)
    });

    useEffect(() => {
        if (fullPhone) {
            updateUserValidation.setFieldValue('phone', fullPhone)
        }
    }, [fullPhone])


    const handleUpdateUserSubmit = async (values: UpdateUserFormValidation) => {
        try {
            let dni = updateUserValidation.values.dni
            let fullDni = ""
            if (dniType && dni)
                fullDni = `${dniType}-${dni}`
            else fullDni = ""

            if (fullDni) {
                //@ts-ignore
                let checkUserValidation = await UserService.GetUserByDNI(fullDni).then(el => el).catch(el => el)
                const checkUserValidationId = checkUserValidation?._id ?? undefined
                console.log(checkUserValidation)
                if (checkUserValidationId && user && checkUserValidationId !== user?._id) {
                    return toast.error('Error al actualizar los datos del perfil. Ya existe un usuario con el mismo número de cédula.');
                }
            }
            if (userImage) {
                toast.info('Actualizando foto de perfil...');
            }
            const updatedUser = await updateUserHandleSubmit({ ...values, dni: fullDni })
            if (userImage) {
                toast.success('Tu foto de perfil ha sido actualizada exitosamente.');
                setUserImage(null)
            } else {
                if (!user?.photoDniBehind || !user?.photoDni) {
                    if (!photoDNIImageFront || !photoDNIImageBack) {
                        toast.success('Tus datos de perfil han sido actualizados exitosamente, pero te falta subir la foto de tu documento de identidad.');
                    } else {
                        toast.success('Tus datos de perfil han sido actualizados exitosamente.');
                    }
                } else {
                    toast.success('Tus datos de perfil han sido actualizados exitosamente.');
                }
            }
            setPhotoDNIImageBack(null)
            setPhotoDNIImageFront(null)
            setPhotoLegalStatus(null)
            dispatch(profileSuccess({ status: "success", data: updatedUser }))
        } catch (error) {
            if (userImage) {
                toast.error('Error al actualizar la foto de perfil. Por favor, intenta nuevamente.');
            } else {
                toast.error('Error al actualizar los datos del perfil. Por favor, intenta nuevamente.');
            }
            dispatch(profileError(user))
        }
    }

    const changePasswordValidation = useFormik<ChangePasswordFormValidation>({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            newPassword: '',
            currentPassword: '',
            confirmNewPassword: ''
        },

        onSubmit: async (values) => {
            try {
                console.log(values)
                if (values.newPassword !== values.confirmNewPassword) {
                    toast.error('Error al actualizar la contraseña. Las contraseñas nuevas no coinciden.');
                    changePasswordValidation.setFieldError('confirmNewPassword', 'Las contraseñas no coinciden')
                } else {
                    await changePasswordHandleSubmit(values)
                }
            } catch (error) {
                /* TODO: Set Error Message */
            }
        }
    });

    const profilePercentage = useMemo(() => {
        const requiredKeys = {
            [Role.Owner]: ['name', 'surname', 'email', 'phone', 'image', 'dni', 'address', 'photoDni', 'photoDniBehind'],
            [Role.Tenant]: ['name', 'surname', 'email', 'phone', 'image', 'dni', 'address', 'photoDni', 'photoDniBehind'],
            [Role.Agent]: ['name', 'surname', 'email', 'phone', 'image', 'dni', 'address', 'photoDni', 'photoDniBehind', 'company', 'companyLogo'],
            [Role.Admin]: ['name', 'surname', 'email', 'phone', 'image']
        };

        if (user) {
            const userRole = user.roles[0];
            const keysToCheck = requiredKeys[userRole] || [];

            const numberFieldsWithValue = keysToCheck.reduce((acc, key) => {
                // @ts-ignore
                return user[key] ? acc + 1 : acc;
            }, 0);

            return Math.round((numberFieldsWithValue / keysToCheck.length) * 100);
        }

        return 0;
    }, [user]);


    useEffect(() => {
        if (userImage) {
            const valuesForm = updateUserValidation.values
            handleUpdateUserSubmit(valuesForm)
        }

    }, [userImage, photoDNIImageFront, photoDNIImageBack, updateUserValidation.values])


    const handleGetRating = useCallback(async (ratingType?: RatingFilter[]) => {
        try {
            if (ratingType)
                for (let i = 0; i < ratingType?.length; i++) {
                    await handleGetRatingApiCall.refetch({
                        limit: 10,
                        offset: 0,
                        filterType: ratingType[i]
                    })
                }
            else {
                await handleGetRatingApiCall.refetch({
                    limit: 10,
                    offset: 0
                })
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        handleGetRating()
    }, [])

    useEffect(() => {
        if (updateUserValidation.values.dni) {
            const _dniSplit = dniSplit(updateUserValidation.values.dni)
            setDniNumber(_dniSplit.dniNumber)
        }
    }, [updateUserValidation.values.dni, user?.dni])


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
        const images = event?.target.files;
        console.log(event?.target.value)
        if (images) {
            const files = Array.from(images);
            if (files[0]) {
                const file = files[0];
                if (event.target.name === 'photoDni') {
                    setPhotoDNIImageFront(file)
                }
                else if (event.target.name === 'image') {
                    setUserImage(file)
                }
                else if (event.target.name === 'photoDniBehind') {
                    setPhotoDNIImageBack(file)
                }
                else if (event.target.name === 'companyLogo') {
                    setPhotoCompanyLogo(file)
                }
                else if (event.target.name === 'legalStatus') {
                    setPhotoLegalStatus(file)
                }
            }
        }
    };

    const handleInputReset = useCallback(() => {
        try {
            if (!photoDNIImageFront) {
                setKeyPhotoDNIImageFront(Date.now())
            }
            if (!photoDNIImageBack) {
                setKeyPhotoDNIImageBack(Date.now())
            }
            if (!photoCompanyLogo) {
                setKeyPhotoCompanyLogo(Date.now())
            }

        } catch (err) {
            console.log(err)
        }
    }, [photoDNIImageFront, photoDNIImageBack, photoCompanyLogo])


    useEffect(() => {
        handleInputReset()
    }, [photoDNIImageFront, userImage, photoDNIImageBack, photoCompanyLogo, handleInputReset])




    const handleRole = (role: Role) => {
        updateUserValidation.setFieldValue('roles', [role])
    }

    const handleChangeDni = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue: string | number = e.target.value;
        inputValue = inputValue.replace("-", "")
        if (inputValue) {
            inputValue = Number(inputValue)
        }

        updateUserValidation.setFieldValue('dni', inputValue)
    };

    useEffect(() => {
        if (user) {
            const _dniSplit = dniSplit(user?.dni ?? '')
            setDniNumber(_dniSplit.dniNumber)
            setDniType(_dniSplit.dniType)
        }
    }, [user])

    const handleResetForm = () => {
        const { countryCode, phoneNumber } = phoneUtilities(user?.phone ?? '')
        if (phoneNumber)
            setPhoneNumber(`${phoneNumber}`)
        else setPhoneNumber('')
        if (countryCode)
            setPhoneDial(countryCode)
        else setPhoneDial(allRegionMap[0].MobileCode)
        updateUserValidation.setValues({
            _id: user?._id ?? '',
            name: user?.name ?? '',
            surname: user?.surname ?? '',
            email: user?.email ?? '',
            phone: user?.phone ?? '',
            roles: user?.roles ?? [Role.Owner],
            image: undefined,
            verifyToken: user?.verifyToken ?? '',
            emailVerified: user?.emailVerified ?? false,
            dni: dniSplit(user?.dni ?? '').dniNumber,
            address: user?.address,
            password: user?.password ?? '',
            company: user?.company,
        })
    }

    useEffect(() => {
        if (user?.roles[0] === Role.Owner) {
            if (checkboxRatingTarget === 'made') {
                handleGetRating(['ratings_made_as_owner'])
            }
            else if (checkboxRatingTarget === 'receive') {
                handleGetRating(['ratings_received_as_owner'])
            }
        } else if (user?.roles[0] === Role.Tenant) {
            if (checkboxRatingTarget === 'made') {
                handleGetRating(['ratings_made_as_tenant'])
            }
            else if (checkboxRatingTarget === 'receive') {
                handleGetRating(['ratings_received_as_tenant'])
            }
        }
    }, [checkboxRatingTarget, user])

    useEffect(() => {
        handleResetForm()
    }, [user?._id])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg profile-setting-img">
                            <img src={top_cover_bar} alt="" height="86" className="profile-wid-img" />
                            {/* <img src={progileBg} className="profile-wid-img" alt="" /> */}

                        </div>
                    </div>
                    <Row>
                        <Col xxl={3}>
                            <Card className="mt-n5">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                            {
                                                !handleUpdateUserApiCall.loading
                                                    ? <img src={user?.image ?? avatar1}
                                                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                        alt="user-profile" />
                                                    : <div className="rounded-circle avatar-xl img-thumbnail user-profile-image d-flex justify-content-center align-items-center">
                                                        <ScaleLoader height={20} />
                                                    </div>

                                            }
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <Input
                                                    id="profile-img-file-input"
                                                    type="file"
                                                    className="profile-img-file-input"
                                                    name='image'
                                                    onChange={handleFileChange}
                                                    accept='image/*'
                                                />
                                                <Label htmlFor="profile-img-file-input"
                                                    className="profile-photo-edit avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                                </Label>
                                            </div>
                                        </div>
                                        {!user?.image && <p className='text-danger'>
                                            La foto de perfil es requerida.
                                        </p>}
                                        <h5 className="fs-16 mb-1">{user?.name} {user?.surname}</h5>

                                    </div>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center mb-5">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">Completar perfil</h5>
                                            <h6 className="text-muted mb-0">Completa tu perfil para subir o alquilar tu primera
                                                propiedad en PartaRentals</h6>
                                        </div>
                                        {/*  <div className="flex-shrink-0">
                                            <Link to="#" className="badge bg-light text-primary fs-12"><i
                                                className="ri-edit-box-line align-bottom me-1"></i> Edit</Link>
                                        </div> */}
                                    </div>
                                    <div className="progress animated-progress custom-progress progress-label">
                                        <div className="progress-bar bg-success" role="progressbar" style={{ "width": `${profilePercentage}%` }}>
                                            <div className="label">{profilePercentage}%</div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            {/* <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">Portfolio</h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Link to="#" className="badge bg-light text-primary fs-12"><i
                                                className="ri-add-fill align-bottom me-1"></i> Add</Link>
                                        </div>
                                    </div>
                                    <div className="mb-3 d-flex">
                                        <div className="avatar-xs d-block flex-shrink-0 me-3">
                                            <span className="avatar-title rounded-circle fs-16 bg-dark text-light">
                                                <i className="ri-github-fill"></i>
                                            </span>
                                        </div>
                                        <Input type="email" className="form-control" id="gitUsername" placeholder="Username"
                                            defaultValue="@daveadame" />
                                    </div>
                                    <div className="mb-3 d-flex">
                                        <div className="avatar-xs d-block flex-shrink-0 me-3">
                                            <span className="avatar-title rounded-circle fs-16 bg-primary">
                                                <i className="ri-global-fill"></i>
                                            </span>
                                        </div>
                                        <Input type="text" className="form-control" id="websiteInput"
                                            placeholder="www.example.com" defaultValue="www.velzon.com" />
                                    </div>
                                    <div className="mb-3 d-flex">
                                        <div className="avatar-xs d-block flex-shrink-0 me-3">
                                            <span className="avatar-title rounded-circle fs-16 bg-success">
                                                <i className="ri-dribbble-fill"></i>
                                            </span>
                                        </div>
                                        <Input type="text" className="form-control" id="dribbleName" placeholder="Username"
                                            defaultValue="@dave_adame" />
                                    </div>
                                    <div className="d-flex">
                                        <div className="avatar-xs d-block flex-shrink-0 me-3">
                                            <span className="avatar-title rounded-circle fs-16 bg-danger">
                                                <i className="ri-pinterest-fill"></i>
                                            </span>
                                        </div>
                                        <Input type="text" className="form-control" id="pinterestName"
                                            placeholder="Username" defaultValue="Advance Dave" />
                                    </div>
                                </CardBody>
                            </Card> */}
                        </Col>

                        <Col xxl={9}>
                            <Card className="mt-xxl-n5">
                                <CardHeader>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        <NavItem style={{
                                            cursor: 'pointer'
                                        }}>
                                            <NavLink
                                                style={activeTab === "1" ? activeClass : {}}
                                                to="#"
                                                className={classnames({ active: activeTab === "1" })}
                                                onClick={() => {
                                                    tabChange("1");
                                                }}>
                                                <i className="fa fa-home"></i>
                                                Detalles personales
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            {!user?.googleLogin && <NavLink
                                                style={activeTab === "2" ? activeClass : {}}
                                                to="#"
                                                className={classnames({ active: activeTab === "2" })}
                                                onClick={() => {
                                                    tabChange("2");
                                                }}
                                                type="button">
                                                <i className="far fa-user"></i>
                                                Contraseña
                                            </NavLink>}
                                        </NavItem>
                                        <NavItem >
                                            <NavLink
                                                style={activeTab === "3" ? activeClass : {}}
                                                to="#"
                                                className={classnames({ active: activeTab === "3" })}
                                                onClick={() => {
                                                    tabChange("3");
                                                }}
                                                type="button">
                                                <i className="far fa-envelope"></i>
                                                Valoraciones
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={activeTab === "4" ? activeClass : {}}
                                                to="#"
                                                className={classnames({ active: activeTab === "4" })}
                                                onClick={() => {
                                                    tabChange("4");
                                                }}
                                                type="button">
                                                <i className="far fa-envelope"></i>
                                                Puntualidad de pagos
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Form onSubmit={(e) => {
                                                setSubmitted(true)
                                                updateUserValidation.handleSubmit(e)
                                            }}>
                                                <Row>
                                                    <Col lg={12}>
                                                        <h5 className='card-title flex-grow-1 mb-3'>Detalles personales</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="firstnameInput" className="form-label">Nombre</Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="firstnameInput"
                                                                placeholder="Ingrese su nombre"
                                                                name='name'
                                                                defaultValue={user?.name}
                                                                value={updateUserValidation.values.name || ""}
                                                                onChange={updateUserValidation.handleChange}
                                                                onBlur={updateUserValidation.handleBlur}
                                                                invalid={
                                                                    updateUserValidation.touched.name && updateUserValidation.errors.name ? true : false
                                                                }
                                                            />
                                                            {
                                                                updateUserValidation.errors.name && <Label className='text-danger'>
                                                                    <div>{updateUserValidation.errors.name}</div>
                                                                </Label>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="lastnameInput" className="form-label">Apellido</Label>
                                                            <Input type="text" className="form-control" id="lastnameInput"
                                                                name='surname'
                                                                defaultValue={user?.surname}
                                                                value={updateUserValidation.values.surname || ""}
                                                                onChange={updateUserValidation.handleChange}
                                                                onBlur={updateUserValidation.handleBlur}
                                                                invalid={
                                                                    updateUserValidation.touched.surname && updateUserValidation.errors.surname ? true : false
                                                                }
                                                                placeholder="Ingrese su apellido" />
                                                            {
                                                                updateUserValidation.errors.surname &&
                                                                <Label className='text-danger' >
                                                                    <div>{updateUserValidation.errors.surname} </div>
                                                                </Label>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>

                                                        {/* 
                                                     */}
                                                        <div className="mb-3">
                                                            <Label htmlFor="phonenumberInput" className="form-label">Whatsapp</Label>
                                                            <Row>
                                                                <Col lg={4}>
                                                                    <Input
                                                                        className="mb-3"
                                                                        type="select"
                                                                        name="phoneDial"
                                                                        id="phoneDial"
                                                                        value={phoneDial}
                                                                        onChange={(e) => setPhoneDial(e.target.value)}
                                                                    >
                                                                        {
                                                                            allRegionMap.map((option, index) => {
                                                                                return <option key={index} value={option.MobileCode}>{option.Code} ({option.MobileCode})</option>
                                                                            })
                                                                        }
                                                                    </Input>
                                                                </Col>
                                                                <Col lg={8}>
                                                                    <Input type="text" className="form-control"
                                                                        id="phonenumberInput"
                                                                        name='phone'
                                                                        placeholder='Escriba sin guiones ni símbolos'
                                                                        defaultValue={phoneNumber}
                                                                        value={phoneNumber || ""}
                                                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                                                        onBlur={updateUserValidation.handleBlur}
                                                                        invalid={
                                                                            updateUserValidation.touched.phone && updateUserValidation.errors.phone ? true : false
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            {updateUserValidation.errors.phone &&
                                                                <Label className='text-danger'>
                                                                    <div>{updateUserValidation.errors.phone}</div>
                                                                </Label>}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="emailInput" className="form-label">Correo electrónico</Label>
                                                            <Input type="email" className="form-control" id="emailInput"
                                                                name='email'
                                                                defaultValue={user?.email}
                                                                value={updateUserValidation.values.email || ""}
                                                                onChange={updateUserValidation.handleChange}
                                                                onBlur={updateUserValidation.handleBlur}
                                                                invalid={
                                                                    updateUserValidation.touched.email && updateUserValidation.errors.email ? true : false
                                                                }
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="JoiningdatInput" className="form-label">Cédula de identidad</Label>
                                                            <Row>
                                                                <Col lg={4}>
                                                                    <Input
                                                                        className="mb-3"
                                                                        type="select"
                                                                        name="dniType"
                                                                        id="dniType"
                                                                        value={dniType}
                                                                        onChange={handleDniTypeChange}
                                                                    >
                                                                        {
                                                                            DNITypesOptions.map((option, index) => {
                                                                                return <option key={index} value={option.value}>{option.label}</option>
                                                                            })
                                                                        }
                                                                    </Input>
                                                                </Col>
                                                                <Col lg={8}>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        //id="phonenumberInput"
                                                                        placeholder="Ingrese su cédula de identidad"
                                                                        name='dni'
                                                                        defaultValue={dniNumber}
                                                                        value={updateUserValidation.values.dni || ""}
                                                                        onChange={handleChangeDni}
                                                                        onBlur={updateUserValidation.handleBlur}
                                                                        invalid={
                                                                            updateUserValidation.touched.dni && updateUserValidation.errors.dni ? true : false
                                                                        }
                                                                    />
                                                                    {
                                                                        updateUserValidation.errors.dni &&
                                                                        <Label className='text-danger' >
                                                                            <div>{updateUserValidation.errors.dni} </div>
                                                                        </Label>
                                                                    }
                                                                </Col>
                                                            </Row>

                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="emailInput" className="form-label">Dirección donde reside</Label>
                                                            <Input type="text" className="form-control"
                                                                name='address'
                                                                defaultValue={user?.address}
                                                                value={updateUserValidation.values.address || ""}
                                                                placeholder="Ingrese su dirección"
                                                                onChange={updateUserValidation.handleChange}
                                                                onBlur={updateUserValidation.handleBlur}
                                                                invalid={
                                                                    updateUserValidation.touched.address && updateUserValidation.errors.address ? true : false
                                                                }
                                                            />
                                                            {
                                                                updateUserValidation.errors.address &&
                                                                <Label className='text-danger' >
                                                                    <div>{updateUserValidation.errors.address} </div>
                                                                </Label>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Row>
                                                        <Col lg={6}>
                                                            {showJuridicDocInput && (

                                                                <div className="mb-3 pb-3">
                                                                    <Label htmlFor="skillsInput" className="form-label">Adjuntar personería jurídica </Label>
                                                                    <Input
                                                                        type="file"
                                                                        className="form-control"
                                                                        name='legalStatus'
                                                                        defaultValue={undefined}
                                                                        onChange={handleFileChange}
                                                                        onBlur={updateUserValidation.handleBlur}
                                                                        invalid={
                                                                            updateUserValidation.touched.legalStatus && updateUserValidation.errors.legalStatus ? true : false
                                                                        }
                                                                    />
                                                                    <div className="list-unstyled mb-0" id="file-previews">
                                                                        {(photoLegalStatus || user?.legalStatus) && (
                                                                            <Card
                                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                key={"-file"}
                                                                            >
                                                                                <div className="p-2 position-relative">
                                                                                    {
                                                                                        photoLegalStatus &&
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn-close position-absolute top-0 end-0 m-2"
                                                                                            aria-label="Close"
                                                                                            onClick={() => {
                                                                                                setPhotoLegalStatus(null)
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    <Row className="align-items-center">
                                                                                        <Col className="col-auto">
                                                                                            <img
                                                                                                data-dz-thumbnail=""
                                                                                                height="80"
                                                                                                className="avatar-sm rounded bg-light"
                                                                                                alt={photoLegalStatus?.name}
                                                                                                src={photoLegalStatus ? URL.createObjectURL(photoLegalStatus) : user?.legalStatus}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Card>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                            )}
                                                        </Col>
                                                    </Row>

                                                    <Col lg={6}>
                                                        <div className="mb-3 border-bottom pb-3">
                                                            <Label htmlFor="skillsInput" id="tooltipTop1" className="form-label">Foto de su documento de identidad (Delantera) <i className='ri-error-warning-line'></i></Label>
                                                            <UncontrolledTooltip placement="top" target="tooltipTop1" >Para hacer un uso correcto de PartaRentals deberá subir su documento de identidad para verificar la misma y garantizar que la plataforma es segura. Nota: no podrá subir propiedades si su cuenta no está verificada.</UncontrolledTooltip>
                                                            <Input
                                                                type="file"
                                                                className="form-control"
                                                                //id="emailInput"
                                                                name='photoDni'
                                                                defaultValue={undefined}
                                                                onChange={handleFileChange}
                                                                onBlur={updateUserValidation.handleBlur}
                                                                invalid={
                                                                    updateUserValidation.touched.photoDni && updateUserValidation.errors.photoDni ? true : false
                                                                }
                                                                accept='image/*'
                                                                key={keyPhotoDNIImageFront}
                                                            />
                                                            <div className="list-unstyled mb-0" id="file-previews">
                                                                {(photoDNIImageFront || user?.photoDni) && (
                                                                    <Card
                                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                        key={"-file"}
                                                                    >
                                                                        <div className="p-2 position-relative">
                                                                            {
                                                                                photoDNIImageFront &&
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-close position-absolute top-0 end-0 m-2"
                                                                                    aria-label="Close"
                                                                                    onClick={() => {
                                                                                        setPhotoDNIImageFront(null)
                                                                                    }}
                                                                                />
                                                                            }
                                                                            <Row className="align-items-center">
                                                                                <Col className="col-auto">
                                                                                    <img
                                                                                        data-dz-thumbnail=""
                                                                                        height="80"
                                                                                        className="avatar-sm rounded bg-light"
                                                                                        alt={photoDNIImageFront?.name}
                                                                                        src={photoDNIImageFront ? URL.createObjectURL(photoDNIImageFront) : user?.photoDni}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Card>
                                                                )}
                                                            </div>
                                                            {
                                                                !user?.photoDni &&
                                                                <Label className='text-danger' >
                                                                    <div>{updateUserValidation.errors.photoDni} </div>
                                                                </Label>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3 border-bottom pb-3">
                                                            <Label htmlFor="skillsInput" id="tooltipTop2" className="form-label">Foto de su documento de identidad (Trasera) <i className='ri-error-warning-line'></i></Label>
                                                            <UncontrolledTooltip placement="top" target="tooltipTop2" >Para hacer un uso correcto de PartaRentals deberá subir su documento de identidad para verificar la misma y garantizar que la plataforma es segura. Nota: no podrá subir propiedades si su cuenta no está verificada.</UncontrolledTooltip>

                                                            <Input
                                                                type="file"
                                                                className="form-control"
                                                                //id="emailInput"
                                                                name='photoDniBehind'
                                                                defaultValue={undefined}
                                                                onChange={handleFileChange}
                                                                onBlur={updateUserValidation.handleBlur}
                                                                invalid={
                                                                    updateUserValidation.touched.photoDniBehind && updateUserValidation.errors.photoDniBehind ? true : false
                                                                }
                                                                value={undefined}
                                                                accept='image/*'
                                                                key={keyPhotoDNIImageBack}
                                                            />

                                                            {(photoDNIImageBack || user?.photoDniBehind) && (
                                                                <Card
                                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                    key={"-file"}
                                                                >
                                                                    <div className="p-2 position-relative">
                                                                        {photoDNIImageBack && <button
                                                                            type="button"
                                                                            className="btn-close position-absolute top-0 end-0 m-2"
                                                                            aria-label="Close"
                                                                            onClick={() => {
                                                                                setPhotoDNIImageBack(null)
                                                                            }}
                                                                        />}
                                                                        <Row className="align-items-center">
                                                                            <Col className="col-auto">
                                                                                <img
                                                                                    data-dz-thumbnail=""
                                                                                    height="80"
                                                                                    className="avatar-sm rounded bg-light"
                                                                                    alt={photoDNIImageBack?.name}
                                                                                    src={photoDNIImageBack ? URL.createObjectURL(photoDNIImageBack) : user?.photoDniBehind}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Card>
                                                            )}
                                                            {
                                                                !user?.photoDniBehind &&
                                                                <Label className='text-danger' >
                                                                    <div>{updateUserValidation.errors.photoDniBehind} </div>
                                                                </Label>
                                                            }
                                                        </div>
                                                    </Col>
                                                    {[Role.Owner, Role.Tenant].includes(user?.roles[0] as Role) && <Col lg={6}>
                                                        {
                                                            [Role.Owner, Role.Tenant].includes(user?.roles[0] as Role) && <div className="mb-3">
                                                                <h5 className='card-title flex-grow-1 mb-3'>Condición actual</h5>
                                                                <Row className='gap-2 m-1'>
                                                                    <FormGroup check >
                                                                        <Label check>
                                                                            <Input
                                                                                name="role"
                                                                                type="radio"
                                                                                checked={updateUserValidation.values.roles && updateUserValidation.values?.roles[0] === Role.Owner}
                                                                                onChange={() => handleRole(Role.Owner)}
                                                                            />
                                                                            {' '}
                                                                            Soy propietario
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup check>
                                                                        <Label check>
                                                                            <Input
                                                                                name="role"
                                                                                type="radio"
                                                                                checked={updateUserValidation.values.roles && updateUserValidation.values?.roles[0] === Role.Tenant}
                                                                                onChange={() => handleRole(Role.Tenant)}
                                                                            />
                                                                            {' '}
                                                                            Soy inquilino
                                                                        </Label>
                                                                    </FormGroup>
                                                                    {/* <FormGroup check>
                                                                <Input
                                                                    name="role"
                                                                    type="radio"
                                                                    checked={updateUserValidation.values.roles && updateUserValidation.values?.roles[0] === Role.Tenant}
                                                                    onChange={() => handleRole(Role.Tenant)}
                                                                />
                                                                {' '}
                                                                <Label check>
                                                                    Soy teniente
                                                                </Label>
                                                            </FormGroup> */}
                                                                </Row>
                                                            </div>}
                                                    </Col>}
                                                    {user?.roles && updateUserValidation.values?.roles && updateUserValidation.values?.roles[0] === Role.Agent && <>
                                                        <Col lg={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="skillsInput" className="form-label">Agencia para la que trabajo (Opcional)</Label>
                                                                <Input type="text" className="form-control"
                                                                    name='company'
                                                                    id="company"
                                                                    placeholder='Ingrese el nombre de la agencia'
                                                                    defaultValue={user?.company}
                                                                    value={updateUserValidation.values.company || ""}
                                                                    onChange={updateUserValidation.handleChange}
                                                                    onBlur={updateUserValidation.handleBlur}
                                                                    invalid={
                                                                        updateUserValidation.touched.company && updateUserValidation.errors.company ? true : false
                                                                    }
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={12}>


                                                            <div className="mb-3">
                                                                <Label htmlFor="skillsInput" className="form-label">Logo de la agencia (Opcional)</Label>
                                                                <Input type="file" className="form-control"
                                                                    //id="emailInput"
                                                                    placeholder="Arrastre archivos aquí para cargar"
                                                                    name='companyLogo'
                                                                    defaultValue={undefined}
                                                                    onChange={handleFileChange}
                                                                    accept='image/*'
                                                                    key={keyPhotoCompanyLogo}
                                                                />

                                                                <div className="list-unstyled mb-0" id="file-previews">
                                                                    {(photoCompanyLogo || user?.companyLogo) && (
                                                                        <Card
                                                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                            key={"-file"}
                                                                        >
                                                                            <div className="p-2 position-relative">
                                                                                {
                                                                                    photoCompanyLogo &&
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn-close position-absolute top-0 end-0 m-2"
                                                                                        aria-label="Close"
                                                                                        onClick={() => {
                                                                                            setPhotoCompanyLogo(null)
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                <Row className="align-items-center">
                                                                                    <Col className="col-auto">
                                                                                        <img
                                                                                            data-dz-thumbnail=""
                                                                                            height="80"
                                                                                            className="avatar-sm rounded bg-light"
                                                                                            alt={photoCompanyLogo?.name}
                                                                                            src={photoCompanyLogo ? URL.createObjectURL(photoCompanyLogo) : user?.companyLogo}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Card>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </>}
                                                    <Col lg={12}>
                                                        <div className="hstack gap-3 justify-content-end">
                                                            <CustomButton type="button"
                                                                className="btn btn-light" onClick={handleResetForm}>Cancelar</CustomButton>
                                                            <CustomButton type="submit"
                                                                loading={handleUpdateUserApiCall.loading}
                                                                className="btn btn-primary">Actualizar</CustomButton>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </TabPane>

                                        <TabPane tabId="2">
                                            <Row>
                                                <Col lg={12}>
                                                    <h5 className='card-title flex-grow-1 mb-3'>Criterios para configurar tu contraseña</h5>
                                                    <List>
                                                        <li>
                                                            Mínimo 12 caracteres
                                                        </li>
                                                        <li>
                                                            Mayúsculas, minúsculas, números y símbolos
                                                        </li>
                                                        <li>
                                                            No usar información personal
                                                        </li>
                                                        <li>
                                                            No reutilizar contraseñas
                                                        </li>
                                                    </List>
                                                </Col>
                                            </Row>
                                            <Form onSubmit={changePasswordValidation.handleSubmit} >
                                                <Row className="g-2">
                                                    <Col lg={4}>
                                                        <div>
                                                            <Label htmlFor="oldpasswordInput" className="form-label">Contraseña actual</Label>
                                                            <Input
                                                                type="password"
                                                                className="form-control"
                                                                id="oldpasswordInput"
                                                                name='currentPassword'
                                                                defaultValue={''}
                                                                value={changePasswordValidation.values.currentPassword || ""}
                                                                onChange={changePasswordValidation.handleChange}
                                                                onBlur={changePasswordValidation.handleBlur}
                                                                invalid={
                                                                    changePasswordValidation.touched.currentPassword && changePasswordValidation.errors.currentPassword ? true : false
                                                                }
                                                                placeholder="Ingrese su contraseña actual" />
                                                        </div>
                                                    </Col>

                                                    <Col lg={4}>
                                                        <div>
                                                            <Label htmlFor="newpasswordInput" className="form-label">Nueva contraseña</Label>
                                                            <Input
                                                                type="password"
                                                                name='newPassword'
                                                                className="form-control"
                                                                id="newpasswordInput"
                                                                defaultValue={''}
                                                                value={changePasswordValidation.values.newPassword || ""}
                                                                onChange={changePasswordValidation.handleChange}
                                                                onBlur={changePasswordValidation.handleBlur}
                                                                invalid={
                                                                    changePasswordValidation.touched.newPassword && changePasswordValidation.errors.newPassword ? true : false
                                                                }
                                                                placeholder="Ingrese la nueva contraseña" />
                                                        </div>
                                                    </Col>

                                                    <Col lg={4}>
                                                        <div>
                                                            <Label htmlFor="confirmpasswordInput" className="form-label">Repetir contraseña</Label>
                                                            <Input type="password" className="form-control"
                                                                id="confirmpasswordInput"
                                                                name='confirmNewPassword'
                                                                defaultValue={''}
                                                                value={changePasswordValidation.values.confirmNewPassword || ""}
                                                                onChange={changePasswordValidation.handleChange}
                                                                onBlur={changePasswordValidation.handleBlur}
                                                                invalid={
                                                                    changePasswordValidation.touched.confirmNewPassword && changePasswordValidation.errors.confirmNewPassword ? true : false
                                                                }
                                                                placeholder="Repita la nueva contraseña"
                                                            />
                                                            {changePasswordValidation.touched.confirmNewPassword && changePasswordValidation.errors.confirmNewPassword ? (
                                                                <FormFeedback type="invalid">{changePasswordValidation.errors.confirmNewPassword}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    {/* <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Link to="#"
                                                                className="link-primary text-decoration-underline">Forgot
                                                                Password ?</Link>
                                                        </div>
                                                    </Col> */}

                                                    {/* <Col lg={12}>
                                                        <div className="text-end">
                                                            <button type="button" className="btn btn-success">Change
                                                                Password</button>
                                                        </div>
                                                    </Col> */}

                                                    <Col lg={12}>
                                                        <div className="hstack gap-2 justify-content-end mt-3">
                                                            <CustomButton type="submit"
                                                                className="btn btn-primary">Actualizar</CustomButton>
                                                            <CustomButton type="button"
                                                                className="btn btn-soft-success">Cancelar</CustomButton>
                                                        </div>
                                                    </Col>

                                                </Row>

                                            </Form>
                                            {/*  <div className="mt-4 mb-3 border-bottom pb-2">
                                                <div className="float-end">
                                                    <Link to="#" className="link-primary">All Logout</Link>
                                                </div>
                                                <h5 className="card-title">Login History</h5>
                                            </div>
                                            <div className="d-flex align-items-center mb-3">
                                                <div className="flex-shrink-0 avatar-sm">
                                                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                                                        <i className="ri-smartphone-line"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>iPhone 12 Pro</h6>
                                                    <p className="text-muted mb-0">Los Angeles, United States - March 16 at
                                                        2:47PM</p>
                                                </div>
                                                <div>
                                                    <Link to="#">Logout</Link>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-3">
                                                <div className="flex-shrink-0 avatar-sm">
                                                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                                                        <i className="ri-tablet-line"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>Apple iPad Pro</h6>
                                                    <p className="text-muted mb-0">Washington, United States - November 06
                                                        at 10:43AM</p>
                                                </div>
                                                <div>
                                                    <Link to="#">Logout</Link>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-3">
                                                <div className="flex-shrink-0 avatar-sm">
                                                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                                                        <i className="ri-smartphone-line"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>Galaxy S21 Ultra 5G</h6>
                                                    <p className="text-muted mb-0">Conneticut, United States - June 12 at
                                                        3:24PM</p>
                                                </div>
                                                <div>
                                                    <Link to="#">Logout</Link>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 avatar-sm">
                                                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                                                        <i className="ri-macbook-line"></i>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h6>Dell Inspiron 14</h6>
                                                    <p className="text-muted mb-0">Phoenix, United States - July 26 at
                                                        8:10AM</p>
                                                </div>
                                                <div>
                                                    <Link to="#">Logout</Link>
                                                </div>
                                            </div> */}
                                        </TabPane>

                                        <TabPane tabId="3">

                                            <Row>
                                                <form>
                                                    <div id="newlink">
                                                        <div id="1">
                                                            <Form>
                                                                <Row className="row-cols-lg-auto g-3 ">
                                                                    <Col>
                                                                        <Label>Visualizar como:</Label>
                                                                    </Col>
                                                                    <Col>
                                                                        <Label check>
                                                                            <Input
                                                                                name="radio1"
                                                                                type="radio"
                                                                                onClick={() => setCheckboxRatingTarget('made')}
                                                                                checked={checkboxRatingTarget === 'made'}
                                                                            />
                                                                            {' '}
                                                                            Valoraciones realizadas
                                                                        </Label>
                                                                    </Col>
                                                                    <Col>
                                                                        <Label check>
                                                                            <Input
                                                                                name="radio1"
                                                                                type="radio"
                                                                                onClick={() => setCheckboxRatingTarget('receive')}
                                                                                checked={checkboxRatingTarget === 'receive'}
                                                                            />
                                                                            {' '}
                                                                            Valoraciones recibidas
                                                                        </Label>
                                                                    </Col>
                                                                </Row>
                                                            </Form>

                                                        </div>
                                                    </div>
                                                </form>
                                            </Row>
                                            <Row>
                                                <div className="m-0 p-0">
                                                    <div className="table-responsive">
                                                        {(!handleGetRatingApiCall.data?.data.length || handleGetRatingApiCall.loading)
                                                            ? <div className="no-information bg-light">
                                                                <p>No hay datos disponibles para visualizar</p>
                                                            </div>
                                                            : <Table className="table align-middle table-nowrap">
                                                                <thead className="table-light">
                                                                    <tr>
                                                                        <th scope="col">NOMBRE DEL {
                                                                            user?.roles[0] === Role.Owner ? 'INQUILINO' : 'PROPIETARIO'
                                                                        }</th>
                                                                        <th scope="col">FECHA</th>
                                                                        <th scope="col">CALIFICACIÓN</th>
                                                                        <th scope="col">MENSAJE</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        handleGetRatingApiCall.data.data.map(review => <ReviewTR key={review._id} {...review} user={user} />)
                                                                    }

                                                                </tbody>
                                                            </Table>
                                                        }

                                                    </div>
                                                </div>
                                            </Row>
                                        </TabPane>

                                        <TabPane tabId="4">
                                            <div className="mb-4 pb-2">
                                                <p className="mb-3">Promedio de Rating</p>
                                                <div className="no-information bg-light">
                                                    <p>No hay datos disponibles para visualizar</p>
                                                </div>
                                                {/* <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                                                    <div className="flex-grow-1">
                                                        <h6 className="fs-14 mb-1">Two-factor Authentication</h6>
                                                        <p className="text-muted">Two-factor authentication is an enhanced
                                                            security meansur. Once enabled, you'll be required to give
                                                            two types of identification when you log into Google
                                                            Authentication and SMS are Supported.</p>
                                                    </div>
                                                    <div className="flex-shrink-0 ms-sm-3">
                                                        <Link to="#"
                                                            className="btn btn-sm btn-primary">Enable Two-facor
                                                            Authentication</Link>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                                                    <div className="flex-grow-1">
                                                        <h6 className="fs-14 mb-1">Secondary Verification</h6>
                                                        <p className="text-muted">The first factor is a password and the
                                                            second commonly includes a text with a code sent to your
                                                            smartphone, or biometrics using your fingerprint, face, or
                                                            retina.</p>
                                                    </div>
                                                    <div className="flex-shrink-0 ms-sm-3">
                                                        <Link to="#" className="btn btn-sm btn-primary">Set
                                                            up secondary method</Link>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                                                    <div className="flex-grow-1">
                                                        <h6 className="fs-14 mb-1">Backup Codes</h6>
                                                        <p className="text-muted mb-sm-0">A backup code is automatically
                                                            generated for you when you turn on two-factor authentication
                                                            through your iOS or Android Twitter app. You can also
                                                            generate a backup code on twitter.com.</p>
                                                    </div>
                                                    <div className="flex-shrink-0 ms-sm-3">
                                                        <Link to="#"
                                                            className="btn btn-sm btn-primary">Generate backup codes</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <h5 className="card-title text-decoration-underline mb-3">Application Notifications:</h5>
                                                <ul className="list-unstyled mb-0">
                                                    <li className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <label htmlFor="directMessage"
                                                                className="form-check-label fs-14">Direct messages</label>
                                                            <p className="text-muted">Messages from people you follow</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="form-check form-switch">
                                                                <Input className="form-check-input" type="checkbox"
                                                                    role="switch" id="directMessage" defaultChecked />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex mt-2">
                                                        <div className="flex-grow-1">
                                                            <Label className="form-check-label fs-14"
                                                                htmlFor="desktopNotification">
                                                                Show desktop notifications
                                                            </Label>
                                                            <p className="text-muted">Choose the option you want as your
                                                                default setting. Block a site: Next to "Not allowed to
                                                                send notifications," click Add.</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="form-check form-switch">
                                                                <Input className="form-check-input" type="checkbox"
                                                                    role="switch" id="desktopNotification" defaultChecked />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex mt-2">
                                                        <div className="flex-grow-1">
                                                            <Label className="form-check-label fs-14"
                                                                htmlFor="emailNotification">
                                                                Show email notifications
                                                            </Label>
                                                            <p className="text-muted"> Under Settings, choose Notifications.
                                                                Under Select an account, choose the account to enable
                                                                notifications for. </p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="form-check form-switch">
                                                                <Input className="form-check-input" type="checkbox"
                                                                    role="switch" id="emailNotification" />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex mt-2">
                                                        <div className="flex-grow-1">
                                                            <Label className="form-check-label fs-14"
                                                                htmlFor="chatNotification">
                                                                Show chat notifications
                                                            </Label>
                                                            <p className="text-muted">To prevent duplicate mobile
                                                                notifications from the Gmail and Chat apps, in settings,
                                                                turn off Chat notifications.</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="form-check form-switch">
                                                                <Input className="form-check-input" type="checkbox"
                                                                    role="switch" id="chatNotification" />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex mt-2">
                                                        <div className="flex-grow-1">
                                                            <Label className="form-check-label fs-14"
                                                                htmlFor="purchaesNotification">
                                                                Show purchase notifications
                                                            </Label>
                                                            <p className="text-muted">Get real-time purchase alerts to
                                                                protect yourself from fraudulent charges.</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="form-check form-switch">
                                                                <Input className="form-check-input" type="checkbox"
                                                                    role="switch" id="purchaesNotification" />
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h5 className="card-title text-decoration-underline mb-3">Delete This
                                                    Account:</h5>
                                                <p className="text-muted">Go to the Data & Privacy section of your profile
                                                    Account. Scroll to "Your data & privacy options." Delete your
                                                    Profile Account. Follow the instructions to delete your account :
                                                </p>
                                                <div>
                                                    <Input type="password" className="form-control" id="passwordInput"
                                                        placeholder="Enter your password" defaultValue="make@321654987"
                                                        style={{ maxWidth: "265px" }} />
                                                </div>
                                                <div className="hstack gap-2 mt-3">
                                                    <Link to="#" className="btn btn-soft-danger">Close &
                                                        Delete This Account</Link>
                                                    <Link to="#" className="btn btn-light">Cancel</Link>
                                                </div>
                                             */}
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default Settings;