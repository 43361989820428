import { APIClient } from "../helpers/api_helper";
import * as url from "../helpers/url-services-parta-rentals/url_helper";
import { IPaymentMethod, ICreatePaymentMethod, IUpdatePaymentMethod, PaymentMethodType } from "types/api/paymentMethod";
const api = new APIClient();

const headers = {
    "Content-Type": "multipart/form-data",
};

export interface GetPaymentMethodsQuery {
    userId: string;
}


export const GetUserPaymentMethods = (filter: GetPaymentMethodsQuery): Promise<IPaymentMethod[]> => api.get(`${url.GET_USER_PAYMENT_METHODS.replace("{userId}", filter.userId)}`);
export const GetPaymentMethod = (id: string): Promise<IPaymentMethod> => api.get(`${url.GET_PAYMENT_METHOD.replace("{id}", id)}`);
export const CreatePaymentMethod = (data: ICreatePaymentMethod): Promise<IPaymentMethod> => {
    // @ts-ignore
    delete data._id

    if (data.type === PaymentMethodType.INTERNATIONAL_BANK_TRANSFER) {
        // @ts-ignore
        data.details.bankAddress = "No-Data"
        // @ts-ignore
        data.details.country = "No-Data"
        // @ts-ignore
        data.details.email = "No-Data"
    }
    data.name = data.type.toUpperCase()
    return api.create(url.CREATE_PAYMENT_METHODS, data);
}
export const UpdatePaymentMethod = (id: string, data: IUpdatePaymentMethod): Promise<IPaymentMethod> => {
    return api.update(url.UPDATE_PAYMENT_METHOD.replace('{id}', id), data)
};
export const DeletePaymentMethod = (id: string): Promise<void> => api.delete(url.DELETE_PAYMENT_METHOD.replace('{id}', id));
/*  */