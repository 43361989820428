import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "types";

interface InitStateType {
  registrationError: null | string;
  message: null | string;
  loading: boolean;
  user: IUser | null;
  success: boolean;
  error: boolean;
  isUserLogout: boolean;
}

export const initialState: InitStateType = {
  registrationError: null,
  message: null,
  loading: false,
  user: null,
  success: false,
  error: false,
  isUserLogout: true,
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    registerUserLoading(state) {
      state.loading = true;
      state.success = false;
      state.registrationError = null;
      state.error = false; // Asegurarse de que el flag de error se resetea
    },
    registerUserSuccessful(state, action: PayloadAction<{ user: IUser }>) {
      state.user = action.payload.user;
      state.loading = false;
      state.success = true;
      state.registrationError = null;
      state.error = false; // Asegurarse de que el flag de error se resetea
    },
    registerUserFailed(state, action: PayloadAction<string>) {
      state.user = null;
      state.loading = false;
      state.registrationError = action.payload;
      state.success = false; // Asegurarse de que el flag de éxito se resetea
      state.error = true;
    },
    resetRegisterFlagChange(state) {
      state.success = false;
      state.error = false;
    },
    apiErrorChange(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
      state.loading = false;
      state.isUserLogout = false;
    },
    resetRegisterState(state) {
      state.registrationError = initialState.registrationError
      state.message = initialState.message
      state.loading = initialState.loading
      state.user = initialState.user
      state.success = initialState.success
      state.error = initialState.error
      state.isUserLogout = initialState.isUserLogout
    }
  },
});

export const {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
  apiErrorChange,
  registerUserLoading,
  resetRegisterState
} = registerSlice.actions;

export default registerSlice.reducer;