import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Collapse } from 'reactstrap';
import navdata from "../LayoutMenuData";
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
import { useSelector } from "react-redux";
import { createSelector } from 'reselect';
import "./styles.css"
import { CustomButton } from 'Components/UI/CustomButton';
import { Role } from "types";
import { useAppDispatch, useAppSelector } from "slices/hooks";
import { useHandleApiCall } from "hooks/useHandleApiCall";
import { profileSuccess } from 'slices/auth/profile/reducer';

import * as UserService from 'service/users';

const VerticalLayout = (props: any) => {
    const navData = navdata().props.children;
    const { user } = useAppSelector(state => state.Profile)
    const path = useLocation().pathname;
    const [isMenuOpenVar, setIsMenuOpened] = useState(false);

    const dispatch = useAppDispatch();
    const history = useNavigate();
    // Estado para el manejo de menús abiertos

    const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});

    // Selección de estados desde Redux
    const selectLayoutState = (state: any) => state.Layout;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            leftsidbarSizeType: layout.leftsidbarSizeType,
            sidebarVisibilitytype: layout.sidebarVisibilitytype,
            layoutType: layout.layoutType
        })
    );

    const {
        leftsidbarSizeType, sidebarVisibilitytype, layoutType
    } = useSelector(selectLayoutProperties);

    // Evento de redimensionamiento de sidebar
    const resizeSidebarMenu = useCallback(() => {
        const windowSize = document.documentElement.clientWidth;

        if (windowSize >= 1025) {
            document.documentElement.setAttribute("data-sidebar-size", leftsidbarSizeType);
            setIsMenuOpened(true)
        } else if (windowSize < 1025 && windowSize > 767) {
            document.documentElement.setAttribute("data-sidebar-size", "sm");
            setIsMenuOpened(false)
        } else {
            document.documentElement.setAttribute("data-sidebar-size", "lg");
            setIsMenuOpened(true)
        }
    }, [leftsidbarSizeType]);

    useEffect(() => {
        window.addEventListener("resize", resizeSidebarMenu, true);
        return () => {
            window.removeEventListener("resize", resizeSidebarMenu, true);
        };
    }, [resizeSidebarMenu]);

    const toggleMenu = (menuKey: string) => {
        setOpenMenus((prevState) => ({
            ...prevState,
            [menuKey]: prevState[menuKey] === undefined ? false : !prevState[menuKey],
        }));
    };

    const isMenuOpen = (menuKey: string) => openMenus[menuKey] === undefined ? true : openMenus[menuKey];

    const getActiveItem = (item: any) => {
        if (item.link === path) {
            return "active"
        } else if (item.subItems) {
            const subItemsLinks = item.subItems.map((subItem: any) => subItem.link)
            if (subItemsLinks.includes(path)) {
                return "active"
            } else if (item.childItems) {
                const childItemsLinks = item.childItems.map((childItem: any) => childItem.link)
                if (childItemsLinks.includes(path)) {
                    return "active"
                } else if (item.childItems) {
                    const subChildItemsLinks = item.childItems.map((subChildItem: any) => subChildItem.link)
                    if (subChildItemsLinks.includes(path)) {
                        return "active"
                    }
                }
            }
        }
        return ""
    }

    const handleUpdateUserApiCall = useHandleApiCall(UserService.Update, {
        onSuccess: (data) => {
            history("/")
        }
    })

    const updateUserHandleSubmit = useCallback(async (e: UserService.IUpdateUser) => {
        if (!e._id) return
        let data = await handleUpdateUserApiCall.refetch({
            id: e._id,
        }, e)
        dispatch(profileSuccess({ status: "success", data }))
        return data
    }, [dispatch, handleUpdateUserApiCall])

    const handleRoleChange = (newMode: Role) => {
        if (user?._id) {
            updateUserHandleSubmit({
                _id: user._id,
                roles: [newMode],
                email: user.email,
                name: user.name,
                emailVerified: user.emailVerified,
                password: user.password,
                phone: user.phone,
                surname: user.surname,
                verifyToken: user.verifyToken,
                address: user.address,
            });
        }
    }

    return (
        <React.Fragment>
            {user?.roles && [Role.Tenant, Role.Owner].includes(user?.roles[0]) && isMenuOpenVar && (
                <Collapse isOpen={isMenuOpenVar} className="menu-dropdown">
                    <div
                        style={{
                            paddingRight: '10px',
                            paddingLeft: '10px',
                        }}>
                        <CustomButton
                            className='btn btn-success fs-14 p-1 w-100'
                            onClick={() => {
                                if (user.roles[0] === Role.Tenant)
                                    handleRoleChange(Role.Owner)
                                if (user.roles[0] === Role.Owner)
                                    handleRoleChange(Role.Tenant)
                            }}
                        >
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                color: 'white',
                                marginBottom: '5px'
                            }}>
                                <span className='justify-left fw-semibold'> Modo {user.roles[0] === Role.Owner ? "Propietario" : "Inquilino"}</span>
                                <i className="ri-user-fill justify-right"></i>
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'start',
                                fontWeight: 'lighter',
                                opacity: '80%',
                            }}>
                                <span style={{
                                    textAlign: "left"
                                }}><i className=" ri-creative-commons-sa-fill" style={{ width: '16px', height: '16px' }}></i> Cambiar a modo {user.roles[0] === Role.Owner ? "inquilino" : "propietario"}</span>
                            </div>
                        </CustomButton>
                    </div>
                </Collapse>
            )}
            {user?.roles && [Role.Agent].includes(user?.roles[0]) && isMenuOpenVar && (
                <Collapse isOpen={isMenuOpenVar} className="menu-dropdown">
                    <div
                        style={{
                            paddingRight: '10px',
                            paddingLeft: '10px',
                        }}>
                        <CustomButton
                            className='btn btn-success fs-14 p-1 w-100'>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                color: 'white',
                                marginBottom: '5px',
                                cursor: 'none'
                            }}>
                                <span className='justify-left fw-semibold'> Agente inmobiliario</span>
                                <i className="ri-user-fill justify-right"></i>
                            </div>
                        </CustomButton>
                    </div>
                </Collapse>
            )}
            {/* Renderizado de los items del menú */}
            {(navData || []).map((item: any, key: number) => (
                <React.Fragment key={key}>
                    {item.isHeader ? (
                        <li className="menu-title">
                            <span data-key="t-menu">{props.t(item.label)} </span>
                        </li>
                    ) : item.subItems ? (
                        <li className="nav-item">
                            <Link
                                className={`nav-link menu-link ${getActiveItem(item)} ${isMenuOpen(`menu-${key}`) ? "menu-open" : ""}`}
                                to="#"
                                onClick={(e) => {
                                    item.click && item.click(e)
                                    toggleMenu(`menu-${key}`)
                                }}
                                data-bs-toggle="collapse"
                            >
                                <i className={item.icon}></i>
                                {<span data-key="t-apps">{props.t(item.label)}</span>}
                                {item.badgeName && (
                                    <span
                                        className={"badge badge-pill bg-" + item.badgeColor}
                                        data-key="t-new"
                                    >
                                        {item.badgeName}
                                    </span>
                                )}
                            </Link>
                            <Collapse isOpen={isMenuOpen(`menu-${key}`)} className="menu-dropdown">
                                <ul className="nav nav-sm flex-column">
                                    {item.subItems.map((subItem: any, subKey: number) => (
                                        <li className="nav-item" key={subKey}>
                                            <Link
                                                to={subItem.link || "#"}
                                                className={`nav-link menu-link ${getActiveItem(subItem)}`}
                                                onClick={(e) => {
                                                    toggleMenu(`submenu-${key}-${subKey}`)
                                                    subItem.click && subItem.click(e)
                                                }}
                                                data-key="t-apps"
                                            >
                                                {props.t(subItem.label)}
                                                {subItem.badgeName && (
                                                    <span
                                                        className={"badge badge-pill bg-" + subItem.badgeColor}
                                                        data-key="t-new"
                                                    >
                                                        {subItem.badgeName}
                                                    </span>
                                                )}
                                            </Link>

                                            {subItem.childItems && (
                                                <Collapse isOpen={isMenuOpen(`submenu-${key}-${subKey}`)}>
                                                    <ul className="nav nav-sm flex-column">
                                                        {subItem.childItems.map((childItem: any, childKey: number) => (
                                                            <li className="nav-item" key={childKey}>
                                                                <Link
                                                                    to={childItem.link || "#"}
                                                                    className="nav-link"
                                                                    onClick={(e) => {
                                                                        toggleMenu(`childmenu-${key}-${subKey}-${childKey}`)
                                                                        childItem.click && childItem.click(e)
                                                                    }}
                                                                >
                                                                    {props.t(childItem.label)}
                                                                </Link>
                                                                {childItem.childItems && (
                                                                    <Collapse isOpen={isMenuOpen(`childmenu-${key}-${subKey}-${childKey}`)}>
                                                                        <ul className="nav nav-sm flex-column">
                                                                            {childItem.childItems.map(
                                                                                (subChildItem: any, subChildKey: number) => (
                                                                                    <li className="nav-item" key={subChildKey}>
                                                                                        <Link
                                                                                            to={subChildItem.link || "#"}
                                                                                            className="nav-link"
                                                                                            onClick={(e) => {
                                                                                                subChildItem.click && subChildItem.click(e)
                                                                                            }}
                                                                                        >
                                                                                            {props.t(subChildItem.label)}
                                                                                        </Link>
                                                                                    </li>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    </Collapse>
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Collapse>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </Collapse>
                        </li>
                    ) : (
                        <li className="nav-item">
                            <Link className="nav-link menu-link" to={item.link || "#"}>
                                <i className={item.icon}></i>
                                <span>{props.t(item.label)}</span>
                                {item.badgeName && (
                                    <span
                                        className={"badge badge-pill bg-" + item.badgeColor}
                                        data-key="t-new"
                                    >
                                        {item.badgeName}
                                    </span>
                                )}
                            </Link>
                        </li>
                    )}
                </React.Fragment>
            ))}
        </React.Fragment>
    );
};

VerticalLayout.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(VerticalLayout));
